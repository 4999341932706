import React, { useEffect } from "react";
// IMPORT COMPONENTS
import Navbar from "./components/Navbar";
import ImageSlider from "./components/ImageSlider";
import VideoSlider from "./components/VideoSlider";
import Leaderboard from "./components/Leaderboard";
import EventsSlider from "./components/EventsSlider";
import ArtistSlider from "./components/ArtistSlider";
import Awards from "./components/Awards";
import TvRadio from "./components/TvRadio";
import Contact from "./components/Contact";
import SocialMedia from "./components/SocialMedia";
import Footer from "./components/Footer";
import { userStore } from "../../../../stores";
import LandingVideoOne from "../../../../assets/wyreVideo.png";
import { IoIosArrowBack } from "react-icons/io5";
// OWL CAROUSEL
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const images = [
  {
    title: "Image Two",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2F3.png?alt=media&token=c95581c5-9123-45ce-914d-bf42279fec51",
  },
  {
    title: "Image One",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fslider%203.jpg?alt=media&token=d6393cce-cee9-4b9b-9d79-0d2ed2abdb8a",
  },
  {
    title: "Image Three",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2F3w.png?alt=media&token=99c0f755-238f-46b4-a705-a1b98727abf2",
  },
];

const songs = [
  {
    title: "Jua Cali",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fvideos%2Fjuacali-57.png?alt=media&token=ac4af74f-362c-4e67-bf2f-3b84e1462ad6",
    link: "/player/premium/album/641325a32b602ff63687f5b6",
  },
  // {
  //   title: "Winrose",
  //   image:
  //     "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fwinrose%20Song.png?alt=media&token=1f927d82-5a93-45d7-aba8-03bdf67500c9",
  //   link: "/player/browse/artist/62148e3755f0306e4be89692",
  // },
  {
    title: "Ayah",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fayah.png?alt=media&token=38e47a33-fd98-4f2e-939e-a169259d80b8",
    link: "/player/browse/content/620bd2d53a1bf303b4641d2b",
  },
  {
    title: "Kizungu zungu",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fkizunguzungu.png?alt=media&token=04594fa8-d615-41cb-8d66-d4d7e7e9dd4d",
    link: "/player/browse/content/62336804a9871ac3763ef82a",
  },
  {
    title: "Pillar",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fpillar.png?alt=media&token=23dedf34-9361-426b-a4e8-88a9f1ec1258",
    link: "/player/browse/content/62220eed5623cd0d1b3bccf8",
  },
  {
    title: "Nadai",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Fnadai.png?alt=media&token=a3b7755e-32a0-4834-8930-e5ccdeb5305c",
    link: "/player/browse/content/622f1b00a30f59ee3f37aa0e",
  },
  {
    title: "Faithful",
    image:
      "https://firebasestorage.googleapis.com/v0/b/bloow-prod.appspot.com/o/files%2Fimages%2Ffaithful.png?alt=media&token=5d0624e8-6f90-4b20-86e6-1fd7bf7d3d3a",
    link: "/player/browse/content/62fa2336de885bcc353ff609",
  },

  // {
  //   title: "Khartoomany",
  //   image:
  //     "https://firebasestorage.googleapis.com/v0/b/bloow-f5237.appspot.com/o/files%2Fimages%2Fartists%2Fgraphics%202-24.png?alt=media&token=515bc6ad-b3c9-47fb-aaae-b4b58a70c582",
  // },
];

const options = {
  loop: true,
  margin: 10,
  nav: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 1,
    },
    1000: {
      items: 1,
    },
  },
  navText: [
    '<div class="text-blue text-4xl -ml-14"><</div>',
    '<div class="text-blue text-4xl -ml-5">></div>',
  ],
};

function UnauthenticatedHome() {
  const user = userStore((state) => state.user);
  useEffect(() => {
    if (user) {
      window.location.href = "/player";
    }
  }, [user]);
  return (
    <div className="bg-midnight pt-6 no-scroll">
      <Navbar />
      <ImageSlider images={images} />

      <section className="my-10">
        <h2 className="text-center text-green  text-4xl">Introducing Bloow</h2>
      </section>

      <VideoSlider image={LandingVideoOne} />

      <ArtistSlider title="Bloow Top 10 Charts" items={songs} />

      <Leaderboard />

      <section className="key--features--section p-10 w-screen overflow-x-hidden">
        <p className="text-white text-2xl font-mono text-center">
          Key Features on Bloow
        </p>
        <div className="w-3/4 mx-auto">
          <OwlCarousel {...options}>
            <div className="item w-full">
              <div className=" w-full ">
                <ul className="text-white font-thin  w-full ">
                  <li>Merchandise</li>
                  <li>Voting & Awards</li>
                  <li>Events & Concerts Booking</li>
                  <li>Milestones</li>
                  <li>Bloow Studio</li>
                  <li>Bloow Label</li>
                  <li>TV & Radio</li>
                  <li>Store</li>
                  <li>NFTs</li>
                  <li>Finance</li>
                  <li>Streaming</li>
                  <li>Portfolio</li>
                  <li>Affiliate Programs</li>
                </ul>
              </div>
            </div>

            <div className="item w-full">
              <div className="">
                <ul className="text-white font-thin w-full">
                  <li>Merchandise</li>
                  <li>Voting & Awards</li>
                  <li>Events & Concerts Booking</li>
                  <li>Milestones</li>
                  <li>Bloow Studio</li>
                  <li>Bloow Label</li>
                  <li>TV & Radio</li>
                  <li>Store</li>
                  <li>NFTs</li>
                  <li>Finance</li>
                  <li>Streaming</li>
                  <li>Portfolio</li>
                  <li>Affiliate Programs</li>
                </ul>
              </div>
            </div>

            <div className="item w-11/12 mx-auto">
              <div className="">
                <ul className="text-white font-thin w-full">
                  <li>Merchandise</li>
                  <li>Voting & Awards</li>
                  <li>Events & Concerts Booking</li>
                  <li>Milestones</li>
                  <li>Bloow Studio</li>
                  <li>Bloow Label</li>
                  <li>TV & Radio</li>
                  <li>Store</li>
                  <li>NFTs</li>
                  <li>Finance</li>
                  <li>Streaming</li>
                  <li>Portfolio</li>
                  <li>Affiliate Programs</li>
                </ul>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </section>

      <EventsSlider />

      <Awards />

      <TvRadio image={LandingVideoOne} />

      <Contact />

      <SocialMedia />

      <Footer />
    </div>
  );
}

export default UnauthenticatedHome;
