import { Spinner } from "../../components/utils/spinningLoader";

export const MainSubmitButton = ({ text, dataTestId, loaderVisibility }) => {
  return (
    <button
      type="submit"
      className="w-full px-6 py-3 bg-bloow-blue text-white rounded-full cursor-pointer font-semibold transition-colors text-center"
      data-testid={dataTestId}
    >
      {loaderVisibility ? <Spinner /> : text}
    </button>
  );
};
