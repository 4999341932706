import { useState } from "react";
import { useHistory } from "react-router-dom";

import singer from "../../assets/singer.jpg";
import { FormInput } from "../form/FormInput";
import { MainSubmitButton } from "../buttons/MainSubmitButton";
import { loginUser } from "../../api/auth";
import { userStore } from "../../stores";
import { Feedback } from "../../components/utils/feedback";

export const Login = ({ setShowSignIn, setShowSignUp, loginText, url }) => {
  const history = useHistory();
  const intialize = {
    email: "",
    password: "",
  };
  const storeUser = userStore((state) => state.storeUser);
  const [{ email, password }, setUser] = useState(intialize);
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [{ feedbackMessage, feedbackText }, setFeedBack] = useState({
    feedbackMessage: "",
    feedbackText: "",
  });
  const [loginArtistImg, setLoginArtistImg] = useState(false);

  const toggleSignIn = () => {
    setShowSignIn((prevState) => !prevState);
  };

  const toggleSignUp = () => {
    setShowSignIn(false);
    setShowSignUp(true);
  };

  const handleChange = (input) => {
    setUser((prevState) => ({
      ...prevState,
      [input.target.name]: input.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    loginUser({ email: email, password: password }).then((response) => {
      setIsLoading(false);
      if (response && response.status === 200) {
        setFormError(false);
        storeUser(response.data.user);
        setShowSignIn(false);
        if (url) {
          return (window.location.href = url);
        }
        if (response.data.user.role == "artist") {
          window.location.href = "/dashboard/artist";
        }
      } else if (response) {
        setFeedBack({
          feedbackMessage: response.data.message,
          feedbackText: "text-red-500",
        });
        setFormError(true);
      }
    });
  };
  const showImg = () => {
    setLoginArtistImg(true);
  };
  return (
    <div
      className="fixed w-full inset-0 z-100 overflow-hidden flex justify-center items-end md:items-center animated fadeIn faster"
      style={{ background: "rgba(0,0,0,.7)" }}
    >
      <div className="shadow-lg modal-container bg-white w-full  md:w-3/5 md:max-w-11/12 mx-auto rounded-t-lg md:rounded-lg shadow-lg z-100 overflow-y-auto max-h-full">
        <div className="modal-content text-left">
          <div className="flex justify-between">
            <div className="hidden md:flex items-center w-full h-full bg-blue-50">
              <img
                src={singer}
                className={`w-full h-full ${loginArtistImg ? "" : "hidden"}`}
                alt=""
                onLoad={showImg}
              />
              {!loginArtistImg && (
                <div className="w-full h-full bg-gray-200 animate-pulse"></div>
              )}
            </div>
            <div className="flex flex-col w-full">
              <div className="flex justify-end p-5 pb-3">
                <svg
                  onClick={toggleSignIn}
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 text-gray-500 cursor-pointer"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="w-full h-full flex flex-col justify-center ">
                <div className="flex justify-center ">
                  <div className="w-3/4 flex flex-col ">
                    <div className="text-xl xl:text-3xl text-center md:text-left font-bold text-gray-700">
                      {loginText || "Experience Passion"}
                    </div>
                    {formError && (
                      <Feedback message={feedbackMessage} type={feedbackText} />
                    )}
                    <div className="py-4 pb-8">
                      <form onSubmit={handleSubmit}>
                        <div className="flex flex-col space-y-4">
                          {/* {hasError && <InputError />} */}
                          <FormInput
                            label={"Email"}
                            type={"email"}
                            name={"email"}
                            placeholder={"grishon@example.com"}
                            dataTestId={"email-input"}
                            onchangeHandler={handleChange}
                            className="text-black"
                          />
                          <FormInput
                            label={"Password"}
                            type={"password"}
                            name={"password"}
                            placeholder={"Enter your password"}
                            dataTestId={"password-input"}
                            onchangeHandler={handleChange}
                            className="text-black"
                          />

                          <div className="w-full flex justify-center pt-2">
                            <div className="w-full">
                              <MainSubmitButton
                                text={"Sign In"}
                                dataTestId={"submit"}
                                loaderVisibility={isLoading}
                              />
                            </div>
                          </div>
                          <div className="text-xs">
                            Don't have an account?{" "}
                            <span
                              className="text-bloow-blue hover:textpurple-700 cursor-pointer"
                              onClick={toggleSignUp}
                              data-testid="signuplink"
                            >
                              Sign up
                            </span>
                          </div>
                          <div className="text-xs text-center">or</div>
                          <div className="text-xs">
                            Forgot password?{" "}
                            <span
                              className="text-bloow-blue hover:textpurple-700 cursor-pointer"
                              onClick={() => {
                                history.push("/resetPassword");
                              }}
                              data-testid="signuplink"
                            >
                              Change password
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
