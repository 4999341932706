import React from 'react'

function  Awards() {
  return (
    <>
        <div className='awards--section flex items-end'>
            <h2 className='text-white text-3xl mb-32 mx-20 mx-auto'>Bloow <br /> Awards</h2>
        </div>
    </>
  )
}

export default Awards