import { useState, useEffect } from "react";
import { userStore } from "../../../../../stores";
import { getAllPremiumContent } from "../../../../../api/auth";

import { ContentCardLoading } from "../../../cards/contentCards";
import { PremiumCard } from "../../../../utils/cards/premiumCards";
const Debut = ({ play }) => {
  const user = userStore((state) => state.user);
  const [page, setPage] = useState(1);
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getContent = async () => {
      setLoading(true);
      const res = await getAllPremiumContent(page);
      setLoading(false);
      if (res && res.status == 200) {
        setContents(res.data);
      }
    };
    getContent();
  }, [page]);

  return (
    <>
      {!loading && contents.length > 0 && (
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center justify-between">
            <div className="font-semibold text-white">Debut Singles</div>
            <div className="flex items-center">
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (page > 1) {
                    setPage((page) => page - 1);
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 ${
                    page === 1 ? "text-white" : "text-gray-300"
                  }`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => {
                  setPage((page) => page + 1);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`h-6 w-6 text-white`}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full h-52 flex gap-x-6 mt-3 overflow-y-scroll no-scrollbar">
            {contents?.map((content, key) => {
              return (
                <PremiumCard
                  key={key}
                  content={content}
                  onClickHandler={play}
                />
              );
            })}
          </div>
        </div>
      )}
      {loading && <ContentLoading />}
    </>
  );
};

export const ContentLoading = () => {
  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex items-center justify-between">
        <div className="w-32 h-7 bg-gray-300 animate-pulse rounded-md"></div>
        <div className="w-16 h-7 bg-gray-300 animate-pulse rounded-md"></div>
      </div>
      <div className="w-full h-52 flex gap-x-6 mt-3 overflow-y-scroll">
        {Array(6)
          .fill(1)
          .map((el, index) => {
            return <ContentCardLoading />;
          })}
      </div>
    </div>
  );
};
export default Debut;
