import { userStore } from "../../stores";
import { logoutUser } from "../../api/auth";
import { MainActionButton } from "../buttons/MainActionButton";
import { Link } from "react-router-dom";
import defaultUser from "../../assets/defaultArtist.jpg";
import { Search } from "./search";
export const NavProfile = ({ setShowSignIn, setShowSignUp }) => {
  const mode = {
    light: {
      text: "text-bloow-black",
      background: "bg-white",
      navLink: "text-black",
      navText: "text-bloow-blue",
    },
    dark: {
      text: "text-white",
      background: "bg-midnight",
      navLink: "text-white",
      navText: "text-white",
    },
  };
  const user = userStore((state) => state.user);
  const storeUser = userStore((state) => state.storeUser);

  const userLogout = () => {
    logoutUser().then((response) => {
      if (response && response.status === 200) {
        storeUser(null);
        window.location.href = "/player";
      }
    });
  };

  const toggleSignUp = () => {
    setShowSignUp((prevState) => !prevState);
  };
  const toggleSignIn = () => {
    setShowSignIn((prevState) => !prevState);
  };

  return (
    <div>
      {(user && (
        <div className=" flex items-center">
          <div className="">
            <Search />
          </div>
          <div className="relative flex items-center group z-100 cursor-pointer p-2.5 px-4 rounded-full">
            <div className="">
              <div className="px-1">
                <img
                  src={
                    user?.image
                      ? (user.image.includes("cloudinary") && defaultUser) ||
                        user.image
                      : "https://i.pinimg.com/474x/65/25/a0/6525a08f1df98a2e3a545fe2ace4be47.jpg"
                  }
                  className="w-8 h-8 rounded-full object-cover"
                  alt="User profile"
                />
              </div>
              <ul
                className={`group-hover:block absolute hidden ${mode.dark.text} w-64 -ml-32 bg-midnight border border-gray-100 m-8 mt-2 z-100  shadow-md`}
              >
                <Link
                  to={user.role === "artist" ? "/dashboard/artist" : "/player"}
                >
                  <li className="flex items-center space-x-4 border-b border-gray-300 py-4 pl-2">
                    <div className="">
                      <img
                        src={
                          user?.image
                            ? (user.image.includes("cloudinary") &&
                                defaultUser) ||
                              user.image
                            : "https://i.pinimg.com/474x/65/25/a0/6525a08f1df98a2e3a545fe2ace4be47.jpg"
                        }
                        className="w-8 h-8 rounded-full object-cover"
                        alt="User profile"
                      />
                    </div>
                    <div className={`capitalize text-sm ${mode.dark.text}`}>
                      {(user && user.username) ||
                        (user && user.email && user.email.split("@")[0])}
                    </div>
                  </li>
                </Link>
                {user.role === "artist" && (
                  <Link to="/dashboard/artist/finance">
                    <li className="flex items-center space-x-4 py-4 pl-2 hover:bg-gray-100 hover:text-black">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <div className="capitalize text-sm">
                        {user?.currency}{" "}
                        <span className="text-green-500 font-semibold">
                          {user?.wallet ? user.wallet.balance : 0}
                        </span>
                      </div>
                    </li>
                  </Link>
                )}
                <li
                  className="flex items-center space-x-4 py-4 pl-2 hover:bg-gray-100 hover:text-black cursor-pointer"
                  onClick={userLogout}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    />
                  </svg>
                  <div className={`capitalize text-sm  `}>Logout</div>
                </li>
              </ul>
            </div>
            <div className="capitalize text-sm text-white">
              {(user && user.username) ||
                (user && user.email && user.email.split("@")[0])}
            </div>
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-5 w-5 ${mode.dark.text}`}
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      )) || (
        <div className="grid grid-cols-3 gap-x-2 mx-2 items-center">
          <div className="">
            <Search />
          </div>
          <div
            className="px-4 py-1 bg-transparent border text-white text-center hover:border-gray-300 font-semibold  rounded-full cursor-pointer"
            onClick={toggleSignIn}
          >
            Sign in
          </div>
          <div className="">
            <MainActionButton
              text={"Create Account"}
              onClickHandler={toggleSignUp}
            />
          </div>
        </div>
      )}
    </div>
  );
};
