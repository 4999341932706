import React from "react";
import { useHistory } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import blurredChart from "../../../../../assets/blurredChart.png";

function ArtistSlider({ title, items }) {
  const history = useHistory();
  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
    navText: [
      '<i class="bi bi-chevron-left text-white fa-10x"></i>',
      '<i class="bi bi-chevron-right text-white"></i>',
    ],
  };
  return (
    <div className="nav--background py-8 w-full flex flex-col items-center">
      <h2 className="text-center text-4xl py-2 ">{title}</h2>

      <div className="  w-11/12  overflow-hidden ">
        <OwlCarousel {...options}>
          {items &&
            items.map((item, index) => (
              <div
                className=" cursor-pointer  "
                onClick={() => {
                  history.push(item?.link);
                }}
              >
                <img
                  className="lazyload"
                  loading="lazy"
                  src={blurredChart}
                  data-src={item.image}
                  alt={item.title}
                />
              </div>
            ))}
        </OwlCarousel>
      </div>
    </div>
  );
}

export default ArtistSlider;
