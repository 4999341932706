import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../../assets/logo.png";

function Footer() {
  return (
    <div className="footer--background p-4">
      <div className="md:flex grid">
        <div className="md:flex md:items-center md:justify-between grid md:w-1/2 w-full mx-auto">
          <div className="flex flex-col m-2">
            <span className="text-white font-semibold text-sm">
              Stay in the loop
            </span>
            <span className="text-white font-semibold text-sm">
              Join our mailing list to stay in the loop with our latest updates
            </span>
            <form>
              <input
                className="w-full mt-4 pt-2 pb-2 pl-7 focus:outline-none bg-white rounded-2xl placeholder:text-sm text-sm"
                type="text"
                placeholder="Your email adress"
              />
              <button className="w-5/12 block float-right bg-white text-black rounded-2xl text-sm font-semibold pl-5 pr-5 pt-1 pb-1 mt-4">
                Send
              </button>
            </form>
          </div>
        </div>
        <div className="md:w-5/12 w-full mt-4 mx-auto text-white">
          <div className="border border-black-800 rounded p-4 w-12/12">
            <span className="font-semibold block text-sm">
              Email :<span className="font-bold">info@bloowafrica.com</span>
            </span>
            <span className="block font-semibold text-sm">
              Phone :<span className="font-bold">+254 729 400 426</span>
            </span>
            <span className="font-semibold text-sm">
              Location :
              <span className="font-bold">
                AfyaRekod Campus, Nairobi, Kenya
              </span>
            </span>
          </div>
          <div className="flex justify-between mt-4">
            <div>
              <p className="font-bold text-white">My Account</p>
              <div className="flex flex-col">
                <Link className="text-white no-underline text-sm" to="/player">
                  Profile
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  Favorites
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  Watchlist
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  My Collections
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  Settings
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  Stats
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  Rankings
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  Activity
                </Link>
              </div>
            </div>
            <div>
              <p className="font-bold text-white">Resources</p>
              <div className="flex flex-col">
                <Link className="text-white no-underline text-sm" to="/player">
                  Help Center
                </Link>
                <Link className="text-white no-underline text-sm" to="/ ">
                  Platform Status
                </Link>
                <Link className="text-white no-underline text-sm" to="/ ">
                  Partners
                </Link>
                <Link className="text-white no-underline text-sm" to="/ ">
                  Gas-Free-
                  <br /> Marketplace
                </Link>
                <Link className="text-white no-underline text-sm" to="/ ">
                  Taxes
                </Link>
                <Link className="text-white no-underline text-sm" to="/ ">
                  Blog
                </Link>
                <Link className="text-white no-underline text-sm" to="/ ">
                  Docs
                </Link>
                <Link className="text-white no-underline text-sm" to="/ ">
                  Newsletter
                </Link>
              </div>
            </div>
            <div className="text-white">
              <p className="font-bold">Company</p>
              <div className="flex flex-col">
                <Link className="text-white no-underline text-sm" to="/player">
                  About
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  Careers
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  Ventures
                </Link>
                <Link className="text-white no-underline text-sm" to="/player">
                  Grants
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
