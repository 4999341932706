import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { FaBell } from "react-icons/fa";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { IoSettingsSharp } from "react-icons/io5";
import { Link, NavLink } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import Login from "../../../../../components/auth/login";
import SignUp from "../../../../../components/auth/signup";
import { userStore } from "../../../../../stores/index";
import { logoutUser } from "../../../../../api/auth";
import defaultArtist from "../../../../../assets/defaultArtist.jpg";
function Navbar() {
  let [open, setOpen] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const user = userStore((state) => state.user);
  const storeUser = userStore((state) => state.storeUser);
  const location = useLocation();
  const userLogout = () => {
    logoutUser().then((response) => {
      if (response && response.status === 200) {
        storeUser(null);
        window.location.href = "/player";
      }
    });
  };
  return (
    <>
      <div className="nav--background w-11/12 mx-auto rounded-2xl h-16 px-4 flex items-center justify-between">
        <div
          onClick={() => setOpen(!open)}
          className="text-3xl  cursor-pointer  block  z-10"
        >
          {open ? <AiOutlineClose /> : <BiMenu />}
        </div>
        <div className="text-3xl text-center  ">
          <Link to="/player" className="font-semibold no-underline text-black">
            Bloow
          </Link>
        </div>
        <div className=" flex space-x-2">
          {!user && (
            <BsFillPersonCheckFill
              onClick={() => setShowSignIn(true)}
              className=" text-2xl"
            />
          )}
          <IoSettingsSharp className=" text-2xl" />
        </div>
      </div>
      {open && (
        <ul
          className={`md:flex md:items-center md:pl-80 pt-2 md:pb-0 pb-12 absolute md:static nav--background
                       w-11/12 rounded-2xl mt-2 right-4 md:p1-0 p1-9 transition-all duration-500 pl-3 z-50
                        ease-in ${open ? "top-20" : "top-[-490px]"}`}
        >
          <li className="cursor-pointer md:ml-8 text-xl md:my-0 my-7 text-black">
            <NavLink
              to="/artists"
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-black no-underline"
                  : "text-black no-underline"
              }
            >
              For Artists
            </NavLink>
          </li>
          <li className="cursor-pointer md:ml-8 text-xl md:my-0 my-7">
            <NavLink
              to="/fans"
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-black no-underline"
                  : "text-black no-underline"
              }
            >
              For Fans
            </NavLink>
          </li>
          <li className="cursor-pointer md:ml-8 text-xl md:my-0 my-7">
            <NavLink
              to="/business"
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-black no-underline"
                  : "text-black no-underline"
              }
            >
              For Business
            </NavLink>
          </li>
          <li className="cursor-pointer md:ml-8 text-xl md:my-0 my-7">
            <NavLink
              to="/player"
              className={({ isActive }) =>
                isActive
                  ? "font-bold text-black no-underline"
                  : "text-black no-underline"
              }
            >
              Music
            </NavLink>
          </li>
          <li>
            <div className="flex items-center justify-start pt-4 md:pt-2">
              {!user && (
                <div
                  className="no-underline text-black font-semibold mb-3 md:mr-2 cursor-pointer "
                  onClick={() => setShowSignIn(true)}
                >
                  SignIn/
                </div>
              )}
              {user && (
                <Link
                  to={user.role == "artist" ? "/dashboard/artist" : "/player"}
                >
                  <div className=" no-underline text-black font-semibold mb-3 mr-4 cursor-pointer">
                    {(user && user.username) ||
                      (user && user.email && user.email.split("@")[0])}
                  </div>
                </Link>
              )}
              {!user && (
                <div
                  className="no-underline text-black font-semibold mb-3 mr-4 cursor-pointer"
                  onClick={() => setShowSignUp(true)}
                >
                  SignUp
                </div>
              )}
              {!user && <BsFillPersonCheckFill className="text-2xl mb-3" />}
              {user && (
                <div className=" group">
                  <img
                    src={
                      user?.image
                        ? user?.image?.includes("cloudinary") ||
                          !user?.image?.includes("prod")
                          ? defaultArtist
                          : user?.image
                        : defaultArtist
                    }
                    className="w-8 h-8 rounded-full object-cover mb-3"
                    alt="User profile"
                  />
                  <ul
                    className={`group-hover:block absolute hidden  w-64 -ml-10 bg-midnight border border-gray-100  -mt-2 z-100  shadow-md`}
                  >
                    <li className="flex items-center space-x-4 border-b border-gray-300 py-4 pl-2">
                      <div className="">
                        <img
                          src={
                            user?.image
                              ? user.image
                              : "https://i.pinimg.com/474x/65/25/a0/6525a08f1df98a2e3a545fe2ace4be47.jpg"
                          }
                          className="w-8 h-8 rounded-full object-cover"
                          alt="User profile"
                        />
                      </div>
                      <Link to="/dashboard/artist">
                        <div className={`capitalize text-sm  text-white`}>
                          {(user && user.username) ||
                            (user && user.email && user.email.split("@")[0])}
                        </div>
                      </Link>
                    </li>

                    <li
                      className="flex items-center space-x-4 py-4 pl-2 text-white  cursor-pointer"
                      onClick={userLogout}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                        />
                      </svg>
                      <div className={`capitalize text-sm  `}>Logout</div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </li>
        </ul>
      )}
      {showSignIn && (
        <Login setShowSignIn={setShowSignIn} setShowSignUp={setShowSignUp} />
      )}
      {showSignUp && (
        <SignUp
          setShowSignIn={setShowSignIn}
          setShowSignUp={setShowSignUp}
          userType={location.pathname === "/artists" ? "artist" : "basic"}
        />
      )}
    </>
  );
}

export default Navbar;
