import { Spinner } from "../../components/utils/spinningLoader";

export const MainActionButton = ({
  text,
  onClickHandler,
  loaderVisibility,
}) => {
  return (
    <div
      className="px-4 py-1.5 text-sm bg-gradient-to-r from-green to-bloow-blue hover:bg-gradient-to-r hover:from-bloow-blue hover:to-green text-white rounded-full cursor-pointer font-semibold transition-all text-center"
      onClick={onClickHandler}
    >
      {loaderVisibility ? <Spinner /> : text}
    </div>
  );
};
