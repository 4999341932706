import { useState, useEffect } from "react";
import { userStore } from "../../../../../stores";
import { getTopTenVotedSongs } from "../../../../../api/auth";
import {
  ContentRoundCard,
  ContentCard,
  ContentRoundCardLoading,
  ContentCardLoading,
} from "../../../cards/contentCards";

function TopVotedSongs() {
  const user = userStore((state) => state.user);
  const [loading, setLoading] = useState(false);

  const [contents, setContents] = useState([]);
  useEffect(() => {
    setLoading(true);
    getTopTenVotedSongs().then((response) => {
      setLoading(false);
      if (response && response?.status === 200) {
        setContents(response?.data?.topTenLikedContent?.contents);
      }
      if (response && response?.status === 204) {
        return;
      }
    });
  }, [user]);
  return (
    <>
      {!loading && contents?.length > 0 && (
        <div className="w-full flex flex-col py-2">
          <div className="w-full flex items-center justify-between">
            <div className="font-semibold text-white">Top Voted Songs</div>
          </div>
          <div className="w-full h-56 flex gap-x-6 mt-3 overflow-y-scroll no-scrollbar">
            {contents?.map((content, index) => {
              if (index === 0 && content.contentId !== null) {
                return <ContentRoundCard key={index} content={content} />;
              } else if (content.contentId !== null) {
                return <ContentCard key={index} content={content} />;
              }
            })}
          </div>
        </div>
      )}
      {loading && <TopVotedSongsLoading />}
    </>
  );
}
const TopVotedSongsLoading = () => {
  return (
    <div className="w-full flex flex-col ">
      <div className="w-full flex items-center justify-between">
        <div className="w-32 h-7 bg-gray-300 animate-pulse rounded-md"></div>
        <div className="w-24 h-7 bg-gray-300 animate-pulse rounded-md"></div>
      </div>
      <div className="w-full h-56 flex gap-x-6 mt-3 overflow-y-scroll no-scrollbar">
        {Array(6)
          .fill(1)
          .map((el, index) => {
            if (index === 0) {
              return <ContentRoundCardLoading />;
            } else {
              return <ContentCardLoading />;
            }
          })}
      </div>
    </div>
  );
};

export default TopVotedSongs;
